import {
  FilterModel,
  IDatasource,
  IGetRowsParams,
  SortModelItem,
} from "ag-grid-community";
import Axios, { AxiosResponse } from "axios";
import { GridPaginatedResponse } from "../interface/GridPaginatedResponse";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import PageContent, { ApiPageContent, convertApiPageContent } from "../interface/PageContent";
import handleGridPaginatedResponse from "./GridPaginatedResponseHandler";

class ContentMgmtServerSideDataSource implements IDatasource {
  private _searchText: string | undefined = "";
  private readonly _gridRef: RefObject<AgGridReact<PageContent> | null>;

  constructor(gridRef: RefObject<AgGridReact<PageContent> | null>, searchText?: string | undefined) {
    this._gridRef = gridRef;
    this._searchText = searchText;
  }

  setSearchText(searchText: string): void {
    this._searchText = searchText;
  }

  // Implementation of the getRows method from IServerSideDatasource
  getRows(params: IGetRowsParams) {
    // Extract necessary parameters from params
    const { startRow, endRow, sortModel, filterModel } = params;

    // Preparing query parameters for pagination, sorting, and filtering
    const data = {
      startRow,
      endRow,
      ...this.getQueryParams(),
      ...this.parseSortModel(sortModel),
      filter: this.parseFilterModel(filterModel),
    };

    this._gridRef.current?.api.setGridOption("loading", true);

    Axios.post<GridPaginatedResponse<ApiPageContent>>(`/content/all`, data)
      .then((response) => {
        const convertedItems = response.data.items.map(convertApiPageContent);
        const convertedResponseData: GridPaginatedResponse<PageContent> = {
          ...response.data,
          items: convertedItems,
        }

        const convertedAxiosResponse: AxiosResponse<GridPaginatedResponse<PageContent>> = {
          ...response,
          data: convertedResponseData
        }

        this._gridRef.current?.api.setGridOption("loading", false);
        const responseHandler = handleGridPaginatedResponse(this._gridRef, params);
        responseHandler(convertedAxiosResponse);
      })
      .catch((error) => {
        this._gridRef.current?.api.setGridOption("loading", false);
        console.error("Error while fetching data from server:", error);
        params.failCallback();
      });
  }

  getQueryParams() {
    if (this._searchText) {
      return { search: this._searchText };
    }

    return {};
  }

  parseSortModel(sortModel: SortModelItem[]) {
    if (sortModel && sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      return {
        sortBy: {
          field: colId,
          order: sort,
        },
      };
    }
    return {};
  }

  private parseFilterModel(filterModel?: FilterModel): any {
    if (filterModel) {
      const filters = [];
      for (const [key, filter] of Object.entries(filterModel)) {
        if (filter instanceof Array) {
          const filterWithField = {
            field: key,
            filterType: "set",
            type: "text",
            values: filter,
          };
          filters.push(filterWithField);
        } else {
          const filterWithField = {
            field: key,
            ...filter,
          };
          filters.push(filterWithField);
        }
      }
      return filters;
    }
    return {};
  }
}

export default ContentMgmtServerSideDataSource;
