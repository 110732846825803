import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ColDef, ColGroupDef, RowClickedEvent } from "ag-grid-community";
import { Tooltip } from "react-tooltip";
import { AgGridReact } from "ag-grid-react";
import { getSearchParams, generateQueryParams } from "../util/Helper";
import svgExclamation from "../images/svg/exclamation.svg";
import QueryParams from "../api/interface/QueryParams";
import {
  apVendorsColumnHeaders,
  defaultColumnHeaders,
} from "../components/tables/ColumnDefinition";
import VendorInfo from "../api/interface/cdh/VendorInfo";
import VendorServerSideDatasource from "../api/GridDataSources/VendorServerSideDataSource";
import DefaultGrid from "../components/tables/DefaultGrid";
import searchSVG from "../images/svg/search.svg";
import clearSVG from "../images/svg/clear.svg";
import useAuth from "../hooks/useAuth";
import PermissionService from "../permissions/PermissionService";
import ColumnCustomizeFilter from "../components/common/ColumnCustomizeFilter";
import filterSVG from "../images/svg/filter.svg";

export default function VendorSearchPage(): ReactElement {
  const [searchParams] = useSearchParams();
  const queryParams: QueryParams = useMemo(
    () => generateQueryParams(searchParams),
    [searchParams],
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const gridRef = useRef<AgGridReact<VendorInfo>>(null);
  const [query, setQuery] = useState<string>("");
  const [searchInput, setSearchInput] = useState("");
  const [isColumnFilterOpen, setIsColumnFilterOpen] = useState(false);

  const isVendor = PermissionService.isVendorUser(user);
  const vendorServerSideDatasource = useMemo(
    () => new VendorServerSideDatasource(gridRef, queryParams.vendorType),
    [queryParams],
  );

  /** Checks for any pending messages to display toast messages. */
  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  const updateSearchParams = useCallback(() => {
    const search: string = getSearchParams(queryParams).join("&");
    if (search !== location.search) {
      localStorage.setItem("VendorSearchQuery", search);
      navigate({
        pathname: `/vendors`,
        search,
      });
    }
  }, [location.search, navigate, queryParams]);

  /** Ag-grid settings starts */
  const [colDefs, setColDefs] =
    useState<(ColDef<VendorInfo> | ColGroupDef<VendorInfo>)[]>(
      defaultColumnHeaders,
    );

  const onRowClicked = (event: RowClickedEvent<VendorInfo>) => {
    const { sapNumber, bbaNumber } = event.node.data!;
    const vendorPath = bbaNumber ? `${sapNumber}/${bbaNumber}` : sapNumber;
    const search = getSearchParams(queryParams).join("&");
    navigate({
      pathname: `/vendor/${vendorPath}/contacts`,
      search,
    });
  };

  const createColumns = (vendor: string) => {
    if (vendor === "ap") {
      setColDefs(apVendorsColumnHeaders);
    } else {
      setColDefs(defaultColumnHeaders);
    }
  };
  /** Ag-grid settings ends  */

  /** Queries the API for page data, when sort, pagination, or filter params change. */
  useEffect(() => {
    setSearchInput(queryParams.query ?? "");
    const vendorType = queryParams.vendorType ?? "bba";
    const searchText = queryParams.query ?? "";
    vendorServerSideDatasource.vendorType = vendorType;
    vendorServerSideDatasource.searchText = searchText;
    createColumns(vendorType);
  }, [query, queryParams, vendorServerSideDatasource]);

  const doSearch = () => {
    setQuery(searchInput);
    queryParams.query = searchInput;
    updateSearchParams();
  };

  const handleClearSearch = () => {
    setSearchInput("");
    setQuery("");
    queryParams.query = "";
    const search: string = getSearchParams(queryParams).join("&");
    localStorage.removeItem("VendorSearchQuery");
    navigate({
      pathname: `/vendors`,
      search,
    });
  };

  const handleCheckboxListToggle = () => {
    setIsColumnFilterOpen(!isColumnFilterOpen);
  };

  const renderSearch = () => {
    return (
      <div className="w-full shrink pl-8 pr-4 lg:min-w-[996px] md:min-w-[650px] sm:min-w-[500px] xs:min-w-[400px] mt-4">
        <div>
          <div className="flex-column">
            <div className="flex pb-4">
              <div className="block font-medium text-lg">Search By</div>
              <img
                id="vendor-rolodex-search-by-tooltip"
                src={svgExclamation as string}
                className="inline-block pl-4 text-gray-dark"
                alt="exclamation icon"
              />
              <Tooltip
                anchorSelect="#vendor-rolodex-search-by-tooltip"
                className="text-sm"
                place="right"
              >
                Search by AP Vendor Number, BBA Vendor Number or Vendor Name
              </Tooltip>
            </div>
            <div className="relative w-full">
              <input
                type="search"
                value={searchInput}
                id="search-dropdown"
                className="z-20 block w-full rounded border border-s-2 border-gray-300 border-s-gray-50 bg-gray-50 p-2.5 text-sm text-gray-900 focus:outline-none focus:ring-0"
                placeholder={
                  queryParams.vendorType === "bba"
                    ? "Search by Name, AP Number, or BBA Number..."
                    : "Search by Name or AP Number"
                }
                required
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    doSearch();
                  }
                }}
              />
              {searchInput && (
                <button
                  type="button"
                  className="absolute end-[60px] top-[13px] text-gray-light"
                  onClick={handleClearSearch}
                >
                  <img src={clearSVG as string} alt="clear search button" />
                </button>
              )}
              <button
                type="submit"
                className="absolute end-0 top-0 h-full rounded-e-lg border border-blue-700 bg-blue-700 p-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                onClick={doSearch}
              >
                <img src={searchSVG as string} alt="search button" />
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderGrid = () => {
    return (
      <DefaultGrid<VendorInfo>
        rowId={(params) => params.data._id ?? ""}
        datasource={vendorServerSideDatasource}
        columnDefs={colDefs}
        onRowClicked={onRowClicked}
        noRowsFoundText="No Vendors Found"
        ref={gridRef}
      />
    );
  };

  const renderActionBar = () => (
    <div
      id="actionBar"
      className="py-4 border-b flex flex-col flex-none bg-white shadow"
    >
      <div className="pl-8 min-w-full">
        <p className="text-3xl no-wrap">
          {isVendor ? "Vendor Profiles" : "Vendor Search"}
        </p>
      </div>
      <div className="flex flex-row w-full flex-wrap">
        <div className="mt-2 border-l border-gray-400" />
        <div className="flex w-full">{renderSearch()} </div>
      </div>
    </div>
  );

  const render = () => (
    <>
      {renderActionBar()}
      <div className="flex pl-8 pt-4 justify-between items-center">
        <button
          type="button"
          className="flex place-content-start w-1/4"
          onClick={handleCheckboxListToggle}
        >
          <div className="self-center justify-items-start bg-blue w-[24px] h-[24px] rounded-full">
            <img
              src={filterSVG as string}
              alt="Filter"
              className="bg-blue inline-block rounded-full w-[18px] h-[18px]"
            />
          </div>
          <p className="inline-block text-blue px-4">Customize Column View</p>
        </button>
      </div>
      <div className="pl-8 justify-between items-center">
        {isColumnFilterOpen && gridRef.current && (
          <ColumnCustomizeFilter
            gridApi={gridRef.current.api}
            onClickOutside={() => setIsColumnFilterOpen(false)}
          />
        )}
      </div>
      <div className=" flex-grow self-center flex w-full box-border relative">
        <div
          className="ag-theme-quartz overflow-x-auto w-full h-full md:p-4 md:h-auto flex-grow" // applying the Data Grid theme
        >
          {renderGrid()}
        </div>
      </div>
      <div id="main-wrapper" className="px-8 py-4 w-full text-left relative" />
    </>
  );
  return render();
}
