import Axios, { HttpStatusCode, InternalAxiosRequestConfig } from "axios";
import { ReactElement, useEffect, useState } from "react";
import AuthApi from "./api/AuthApi";
import Logger from "./common/Logger";
import useAuth from "./hooks/useAuth";
import { DefaultLoadingLayout } from "./layout/DefaultLayout";
import ErrorPage from "./pages/ErrorPage";
import MaintenancePage from "./pages/MaintenancePage";
import Router from "./Router";

enum ConnectionStatus {
  CONNECTING,
  CONNECTED,
  FAILED,
  MAINTENANCE,
}

export default function App(): ReactElement {
  const { user, setUser, authTokens, setAuthTokens } = useAuth();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
    ConnectionStatus.CONNECTING,
  );

  useEffect(() => {
    window.addEventListener("pageshow", (event) => {
      if (event.persisted && sessionStorage.length === 0) {
        window.location.reload();
      }
    });
  }, []);

  const accessTokenInterceptor = (request: InternalAxiosRequestConfig) => {
    Logger.logFine("Attempting to attach access token...");
    if (authTokens?.access_token) {
      request.headers.Authorization = `Bearer ${authTokens.access_token}`;
      Logger.logFine(`Bearer token set. Sending authenticated request.`);
    } else {
      Logger.logFine("Sending unauthenticated request.");
    }
    return request;
  };

  Axios.interceptors.request.use(accessTokenInterceptor);

  const attemptConnection = () => {
    Axios.get("/healthcheck", { timeout: 30000 })
      .then(() => {
        setConnectionStatus(ConnectionStatus.CONNECTED);
      })
      .catch((e) => {
        // Workaround for APIM requirement of Auth on all endpoints
        if (e.code === "ECONNABORTED") {
          Logger.logError("Request time out");
        }
        if (e.response?.status === HttpStatusCode.Unauthorized) {
          setConnectionStatus(ConnectionStatus.CONNECTED);
        } else {
          setConnectionStatus(ConnectionStatus.FAILED);
        }
      });
  };

  const attemptAuthorize = () => {
    if (!authTokens) {
      AuthApi.getTokens().then((tokens) => {
        if (!tokens) {
          AuthApi.authorize().then();
        } else {
          Logger.log("Authorized token from auth token...");
          setAuthTokens(tokens);
        }
      });
    }
  };

  const attemptLogin = () => {
    if (!user && authTokens) {
      AuthApi.getUserInfo(authTokens).then((userInfo) => {
        if (userInfo?.active) {
          setUser(userInfo);
        } else {
          attemptAuthorize();
        }
      });
    } else {
      // Todo: use refresh token to validate that the login is still valid, and use request timeout to determine the connection status.
    }
  };

  const render = () => {
    if (connectionStatus === ConnectionStatus.MAINTENANCE) {
      return <MaintenancePage />;
    }

    if (connectionStatus === ConnectionStatus.FAILED) {
      sessionStorage.setItem(
        "errorTitle",
        "Error Code: 500 Internal Server Error",
      );
      sessionStorage.setItem(
        "errorMessage",
        `We are working to fix the problem. In the meantime please
          refresh the page or try again in few minutes.`,
      );
    }

    const title = sessionStorage.getItem("errorTitle");
    const message = sessionStorage.getItem("errorMessage");
    const details = sessionStorage.getItem("errorDetails");

    if (title && message) {
      sessionStorage.clear();
      return (
        <ErrorPage
          title={title}
          message={message}
          details={details ?? undefined}
        />
      );
    }

    if (connectionStatus === ConnectionStatus.CONNECTING) {
      attemptConnection();
      return DefaultLoadingLayout;
    }

    if (!authTokens) {
      attemptAuthorize();
      return DefaultLoadingLayout;
    }

    if (!user) {
      attemptLogin();
      return DefaultLoadingLayout;
    }

    return <Router />;
  };

  return render();
}
