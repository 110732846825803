import {ColDef, ColGroupDef} from "ag-grid-community";
import PageContent from "../../../api/interface/PageContent";
import SectionFilter from "./SectionFilter";

export const TAEColDefs: (ColDef<PageContent> | ColGroupDef<PageContent>)[] = [
    {
        headerName: "Title",
        field: "title",
        tooltipField: "title",
        filter: true,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 2
    },
    {
        headerName: "Description",
        field: "subtitle",
        tooltipField: "subtitle",
        filter: true,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
    {
        headerName: "Author",
        field: "author",
        filter: true,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
    {
        headerName: "Section",
        field: "section",
        valueFormatter: (content) => {
            switch (content.value) {
                case "policies-and-guidelines":
                    return "Policies & Guidelines";
                case "tools-and-templates":
                    return "Tools & Templates";
                case "job-aids":
                    return "Job Aids";
                case "videos":
                    return "Videos";
                default:
                    return "N/A"

            }
        },
        filter: SectionFilter,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
    {
        headerName: "Publish Date",
        field: "publishDate",
        filter: "agDateColumnFilter",
        valueFormatter: (params) => {
            const pageContent = params.data;
            return pageContent?.publishDate?.toLocaleDateString() ?? "";
        },
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
];
