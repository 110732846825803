import {
  IDatasource,
  IGetRowsParams,
  SortModelItem,
} from "ag-grid-community";
import Axios from "axios";
import ItemData from "../interface/cdh/ItemData";
import { GridPaginatedResponse } from "../interface/GridPaginatedResponse";
import VendorInfo from "../interface/cdh/VendorInfo";
import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import handleGridPaginatedResponse from "./GridPaginatedResponseHandler";

class ItemServerSideDatasource implements IDatasource {
  private _searchText?: string = "";
  private readonly _vendor?: VendorInfo;
  private readonly _gridRef: RefObject<AgGridReact<ItemData> | null>;

  constructor(gridRef: RefObject<AgGridReact<ItemData> | null>, vendor?: VendorInfo) {
    this._vendor = vendor;
    this._gridRef = gridRef;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  // Implementation of the getRows method from IServerSideDatasource
  getRows(params: IGetRowsParams) {
    // Extract necessary parameters from params
    const { startRow, endRow, sortModel } = params;

    // Preparing query parameters for pagination, sorting, and filtering
    const queryParams = {
      startRow: startRow,
      endRow: endRow,
      ...this.getQueryParams(),
      ...this.parseSortModel(sortModel),
    };

    this._gridRef.current?.api.setGridOption("loading", true);

    // Make an axios call to fetch data
    Axios.get<GridPaginatedResponse<ItemData>>(this.getUrl(), {
      params: queryParams,
    })
      .then((response) => {
        this._gridRef.current?.api.setGridOption("loading", false);
        const responseHandler = handleGridPaginatedResponse(this._gridRef, params);
        responseHandler(response);
      })
      .catch((error) => {
        this._gridRef.current?.api.setGridOption("loading", false);
        console.error("Error while fetching data from server:", error);
        params.failCallback();
      });
  }

  private getUrl() {
    let itemPath;
    if (this._vendor) {
      const pathSuffix = this._vendor.bbaNumber ? `/${this._vendor.bbaNumber}` : "";
      const pathId = `${this._vendor.sapNumber}${pathSuffix}`;
      itemPath = `/items/vendor/${pathId}`;
    } else {
      itemPath = "/items"
    }

    return itemPath;
  }

  getQueryParams() {
    if (this._searchText) {
      return { search: this._searchText };
    }

    return {};
  }

  parseSortModel(sortModel: SortModelItem[]) {
    if (sortModel && sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      // If sort is ascending, no minus necessary before sort column
      const sortDir = sort === "asc" ? "" : "-";
      return { sortBy: `${sortDir}${colId}` };
    }
    return {};
  }
}

export default ItemServerSideDatasource;
