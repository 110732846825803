import { useNavigate } from "react-router-dom";
import ContentApi from "../api/ContentApi";

const ContentResetPage = () => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/content");
  };

  const reset = () => {
    ContentApi.reset().then(() => {
      redirect();
    });
  };

  const render = () => (
    <div className="my-10 mx-auto flex max-w-lg flex-col items-center rounded-md border px-8 py-10 text-gray-800 shadow-lg">
      <div className="h-24 w-24 rounded-xl bg-red-100 p-2 text-red-500">
        <span className="material-icons text-7xl p-1">delete</span>
      </div>
      <p className="mt-4 text-center text-xl font-bold">Reset all content</p>
      <p className="mt-2 text-center text-lg">
        Are you sure you want to reset all portal homepage content?
      </p>
      <div className="mt-8 flex flex-col justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
        <button
          type="button"
          onClick={() => reset()}
          className="whitespace-nowrap rounded-md bg-red-500/90 hover:bg-red-500/100 px-4 py-3 font-medium text-white"
        >
          Yes, remove all content
        </button>
        <button
          type="button"
          onClick={redirect}
          className="pointer whitespace-nowrap rounded-md bg-gray-200 hover:bg-gray-400 hover:text-gray-900 px-4 py-3 font-medium"
        >
          No, keep current content
        </button>
      </div>
    </div>
  );
  return render();
};

export default ContentResetPage;
