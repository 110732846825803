import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import TokenResponse from "../api/interface/oauth/TokenResponse";
import UserInfo from "../api/interface/oauth/UserInfo";

type AuthContextType = {
  user?: UserInfo;
  setUser: Dispatch<SetStateAction<UserInfo | undefined>>;
  authTokens: TokenResponse | null;
  setAuthTokens: Dispatch<SetStateAction<TokenResponse | null>>;
};

const AuthContext = createContext<null | AuthContextType>(null);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserInfo | undefined>();
  const [authTokens, setAuthTokens] = useState<TokenResponse | null>(null);

  if (authTokens) {
    sessionStorage.setItem("access_token", authTokens.access_token);
    sessionStorage.setItem("refresh_token", authTokens.refresh_token ?? "");
    sessionStorage.setItem("id_token", authTokens.id_token ?? "");
    sessionStorage.removeItem("cv");
  } else if (sessionStorage.getItem("access_token")) {
    const authToken: TokenResponse = {
      access_token: sessionStorage.getItem("access_token")!,
      refresh_token: sessionStorage.getItem("refresh_token")!,
      id_token: sessionStorage.getItem("id_token")!,
    };
    setAuthTokens(authToken);
  }

  const memoizedValues = useMemo(() => {
    return {
      user,
      setUser,
      authTokens,
      setAuthTokens,
    };
  }, [user, authTokens]);

  return (
    <AuthContext.Provider value={memoizedValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
