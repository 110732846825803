import { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingPage from "../pages/LoadingPage";
import Header from "./Header";
import Footer from "./Footer";
import { PageDataContextProvider } from "../context/PageDataContext";

const pageDataContextProvider: ReactElement = (
  <PageDataContextProvider>
    <Outlet />
  </PageDataContextProvider>
);

export const DefaultLayoutStructure = (element: ReactElement) => (
  <div className="min-h-screen flex flex-col">
    <Header />
    <main className="w-full bg-white flex-grow flex-1 flex flex-col wf">
      {element}
    </main>
    <Footer />
    {/** Add global modal panes. */}
    <ToastContainer />
  </div>
);

const DefaultLayout = () => DefaultLayoutStructure(pageDataContextProvider);
export const DefaultLoadingLayout = DefaultLayoutStructure(<LoadingPage />);

export default DefaultLayout;
