import { GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useSearchParams } from "react-router-dom";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import ContentApi from "../../api/ContentApi";
import Logger from "../../common/Logger";
import PageContent from "../../api/interface/PageContent";
import VideoContent from "../../components/content/VideoContent";
import DefaultGrid from "../../components/tables/DefaultGrid";
import { TAEColDefs } from "./TrainingAndEducationProperties/TrainingAndEducationPageColumnDefinitions";
import SearchBar from "../../components/common/SearchBar";
import QueryParams from "../../api/interface/QueryParams";
import { generateContentQueryParams } from "../../util/Helper";

const TrainingAndEducationPage = (): ReactElement => {
  const [TAERowData, setTAERowData] = useState<PageContent[]>([]);
  const [quickFilterText, setQuickFilterText] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const gridRef = useRef<AgGridReact<PageContent>>(null);
  const [videoContent, setVideoContent] = useState<PageContent | undefined>();
  const [searchParams] = useSearchParams();
  const queryParams: QueryParams = useMemo(
    () => generateContentQueryParams(searchParams),
    [searchParams],
  );

  useEffect(() => {
    if (gridRef?.current) {
      ContentApi.getTandEDocs()
        .then((docs) => {
          setTAERowData(docs);
        })
        .catch((error) => {
          Logger.logError("Error while fetching data from server:", error);
        });
      setLoading(false);
    }
  }, [setTAERowData]);

  /** Checks for any pending messages to display toast messages. */
  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  const renderSearch = () => {
    return (
      <div className="w-full shrink pl-12 pr-4 lg:min-w-[996px] md:min-w-[650px] sm:min-w-[500px] xs:min-w-[400px] mt-4">
        <div className="pb-4">
          {/* {searchError && ( */}
          {/*   <div className="w-4/5 p-2"> */}
          {/*     <SearchErrorPrompt dismiss={handleCloseError} /> */}
          {/*   </div> */}
          {/* )} */}
        </div>
        <div className="flex w-full justify-between items-center">
          <SearchBar
            value={queryParams.query ?? ""}
            setValue={(value) => {
              setQuickFilterText(value);
            }}
            searchType="trainingAndEducation"
            placeholder="Search by Title"
          />
        </div>
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
        />
      </div>
    );
  };
  const renderActionBar = () => (
    <div
      id="actionBar"
      className="py-4 border-b flex flex-col flex-none bg-white shadow"
    >
      <div className="pl-12 min-w-full">
        <p className="text-3xl no-wrap">Training & Education Library</p>
      </div>
      <div className="flex flex-row w-full flex-wrap">
        <div className="mt-2 border-l border-gray-400" />
        <div className="flex w-full pr-8">{renderSearch()} </div>
      </div>
    </div>
  );
  /** Navigates to a page for creating/viewing/modifying a PageContent item. */
  const viewContent = (pageContent: PageContent | undefined) => {
    const target = pageContent?.link;
    if (target && URL.canParse(target)) {
      // Check if file is a video content, if it is, show the VideoContent component
      const fileUrl = new URL(target);
      const fileEnd = fileUrl.pathname.split(".").pop() ?? "";
      const extension = fileEnd.toLowerCase();
      if (extension === "mp4" || extension === "webm") {
        setVideoContent(pageContent);
      } else {
        window.open(fileUrl, "_blank");
      }
    }
  };

  const onRowClicked = (event: RowClickedEvent<PageContent>) => {
    viewContent(event.data);
  };

  const onFilterChanged = () => {
    if (gridRef?.current) {
      const filteredRowCount = gridRef.current.api.getDisplayedRowCount();
      if (filteredRowCount === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
  };

  const onGridReady = (event: GridReadyEvent<PageContent>) => {
    if (queryParams.contentFilter) {
      event.api
        .setColumnFilterModel("section", [queryParams.contentFilter])
        .then(() => event.api.onFilterChanged());
    }
  };

  const renderGrid = () => {
    return (
      <DefaultGrid<PageContent>
        rowId={(params) => params.data._id ?? ""}
        rowData={TAERowData}
        columnDefs={TAEColDefs}
        loading={loading}
        onGridReady={onGridReady}
        onRowClicked={onRowClicked}
        onFilterChanged={onFilterChanged}
        noRowsFoundText="No Training and Education Material Found"
        quickFilterText={quickFilterText}
        ref={gridRef}
      />
    );
  };

  const render = () => (
    <div className="min-h-screen flex flex-col">
      {/* // Clicking outside the video content div should close the video content. */}
      {videoContent && (
        <VideoContent
          item={videoContent}
          onClose={() => setVideoContent(undefined)}
        />
      )}
      <div className="">{renderActionBar()}</div>
      <div className="flex-row flex-grow self-center w-full box-border relative">
        <div id="main-wrapper" className="px-8 py-4 w-full text-left relative">
          <div className="overflow-x-auto w-full md:p-4 min-w-96">
            {renderGrid()}
          </div>
        </div>
      </div>
    </div>
  );

  return render();
};

export default TrainingAndEducationPage;
