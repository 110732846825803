import svgLoading from "../images/svg/loading.svg";

const LoadingPage = () => (
  <div className="flex flex-grow items-center justify-center z-10">
    <div className="flex flex-col items-center">
      <div className="w-16 h-16">
        <img src={svgLoading as string} alt="loading..." />
      </div>
      <div className="pt-4 text-lg text-center">Loading...</div>
    </div>
  </div>
);

export default LoadingPage;
