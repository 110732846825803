import Logger from "../common/Logger";

type FrontendAppSettings = {
  bff_base_url: string;
  openid_configuration: string;
  ping_client_id: string;
  cloud_environment: string;
  costco_environment: string;
  vam_url: string;
}

export default class AppSettingsService {
  static settings: FrontendAppSettings | undefined;

  static async fetchAndSetAppSettings() {
    try {
      const response = await fetch("/appsettings.json");
      const appSettings = await response.json();
      AppSettingsService.populateAppSettings(appSettings);
      Logger.logInfo(
        `Response was ${appSettings} and app settings are ${AppSettingsService.settings}`,
      );
    } catch (error) {
      Logger.logError(`Failed to retrieve App Settings! ${error}`);
    }
  }

  private static populateAppSettings(appSettings: FrontendAppSettings) {
    AppSettingsService.settings = {
      ...appSettings,
    };
  }

  static getBaseUrl(): string {
    return AppSettingsService.settings?.bff_base_url!;
  }

  static getOpenIdConfigUrl(): string {
    return AppSettingsService.settings?.openid_configuration!;
  }

  static getPingClientId(): string {
    return AppSettingsService.settings?.ping_client_id!;
  }

  static getVAMUrl(): string {
    return AppSettingsService.settings?.vam_url!;
  }

  static getCostcoEnvironment(): string {
    return AppSettingsService.settings?.costco_environment!;
  }

  static getCloudEnvironment(): string {
    return AppSettingsService.settings?.cloud_environment!;
  }
}
