import Severity from "./Severity";
import { getTimestamp } from "../util/Utils";

export default class Logger {
  private static LOG_LEVEL: Severity = Severity.DEBUG;

  private static shouldLog(severity?: Severity): boolean {
    return (
      severity === undefined ||
      Logger.getSeverityValue(severity) >=
        Logger.getSeverityValue(Logger.LOG_LEVEL)
    );
  }

  private static getSeverityValue(s?: Severity): number {
    switch (s) {
      case Severity.ERROR:
        return 1000;
      case Severity.WARNING:
        return 100;
      case Severity.INFO:
        return 10;
      case Severity.FINE:
        return 1;
      case Severity.DEBUG:
      default:
        return -1;
    }
  }

  private static consoleLog(
    message: string,
    severity?: Severity,
    cause?: unknown,
  ): void {
    switch (severity) {
      case Severity.ERROR:
        if (cause) {
          console.error(message, cause);
        } else {
          console.error(message);
        }
        break;
      case Severity.WARNING:
        console.warn(message);
        break;
      case Severity.INFO:
        console.info(message);
        break;
      case Severity.FINE:
      case Severity.DEBUG:
        console.debug(message);
        break;
      default:
        console.log(message);
        break;
    }
  }

  public static log(
    message: string,
    severity?: Severity,
    cause?: unknown,
  ): string {
    const content = `${Logger.getTimeStamp()} - ${message}`;
    if (Logger.shouldLog(severity)) {
      Logger.consoleLog(content, severity, cause);
    }
    if (severity === Severity.ERROR) {
      // log to server.
    }
    return `\n${content}`;
  }

  public static logWarning(message: string): string {
    return Logger.log(message, Severity.WARNING);
  }

  public static logError(message: string, cause?: unknown): string {
    return Logger.log(message, Severity.ERROR, cause);
  }

  public static logInfo(message: string): string {
    return Logger.log(message, Severity.INFO);
  }

  public static logFine(message: string): string {
    return Logger.log(message, Severity.FINE);
  }

  public static logDebug(log: any): string {
    const message = log.toString();
    return Logger.log(message, Severity.DEBUG);
  }

  static getTimeStamp(): string {
    return getTimestamp();
  }
}
