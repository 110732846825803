import { RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import ContentMgmtServerSideDataSource from "../../api/GridDataSources/ContentMgmtServerSideDataSource";
import PageContent from "../../api/interface/PageContent";
import QueryParams from "../../api/interface/QueryParams";
import ColumnCustomizeFilter from "../../components/common/ColumnCustomizeFilter";
import SearchBar from "../../components/common/SearchBar";
import StyledButton from "../../components/common/StyledButton";
import DefaultGrid from "../../components/tables/DefaultGrid";
import useAuth from "../../hooks/useAuth";
import svgExclamation from "../../images/svg/exclamation.svg";
import filterSVG from "../../images/svg/filter.svg";
import Role from "../../permissions/interface/Role";
import PermissionService from "../../permissions/PermissionService";
import { generateContentQueryParams } from "../../util/Helper";
import { CMColDefs } from "./ContentManagementProperties/ContentManagementPageColumnDefintions";

export default function ContentManagementPage(): ReactElement {
  const [searchParams] = useSearchParams();
  const queryParams: QueryParams = useMemo(
    () => generateContentQueryParams(searchParams),
    [searchParams],
  );
  const navigate = useNavigate();
  const { user } = useAuth();
  const gridRef = useRef<AgGridReact<PageContent>>(null);
  const [isColumnFilterOpen, setIsColumnFilterOpen] = useState(false);

  const contentMgmtServerSideDatasource = useMemo(
    () => new ContentMgmtServerSideDataSource(gridRef, queryParams.query),
    [queryParams.query],
  );

  /** Checks for any pending messages to display toast messages. */
  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  /** Navigates to a page for creating/viewing/modifying a PageContent item. */
  const manageContent = (pageContent: PageContent | undefined) => {
    const id = pageContent?._id ?? "new";
    navigate({
      pathname: `/content/item/${id}`,
    });
  };
  const renderSearch = () => {
    return (
      <div className="w-full shrink pl-12 pr-4 lg:min-w-[996px] md:min-w-[650px] sm:min-w-[500px] xs:min-w-[400px] mt-4">
        <div className="pb-4">
          <div className="font-medium text-lg inline-flex">Search By</div>
          <img
            id="item-rolodex-search-by-tooltip"
            src={svgExclamation as string}
            className="inline-flex pl-4 pb-1 text-gray-dark"
            alt="exclamation icon"
          />
          <Tooltip
            anchorSelect="#item-rolodex-search-by-tooltip"
            className="text-sm"
            place="right"
          >
            Search by Title
          </Tooltip>
          {/* {searchError && ( */}
          {/*   <div className="w-4/5 p-2"> */}
          {/*     <SearchErrorPrompt dismiss={handleCloseError} /> */}
          {/*   </div> */}
          {/* )} */}
        </div>
        <div className="flex w-full justify-between items-center">
          <SearchBar
            value={queryParams.query ?? ""}
            setValue={() => {
              gridRef.current?.api.setFilterModel(null);
            }}
            searchType="test"
            placeholder="Search by Title"
          />
        </div>
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
        />
      </div>
    );
  };

  const renderActionBar = () => {
    const hasCreatePermissions = PermissionService.hasPermissionLevel(
      user,
      Role.COSTCO_CONTENTMGMT_ADMIN,
      Role.COSTCO_CONTENTMGMT_CREATOR,
    );
    return (
      <div
        id="actionBar"
        className="flex flex-row py-4 flex-none border-b bg-white shadow"
      >
        <div className="flex-item flex-col mb-3 min-w-full">
          <p className="text-3xl no-wrap ml-4 pl-8">Manage Content</p>
          <div className="flex flex-row justify-around items-end">
            {renderSearch()}
            {hasCreatePermissions && (
              <div className="flex-item flex-grow flex space-x-2 mr-8 ml-10 h-10 mb-[1px]">
                <StyledButton
                  text="Create"
                  onClick={() => {
                    manageContent(undefined);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleCheckboxListToggle = () => {
    setIsColumnFilterOpen(!isColumnFilterOpen);
  };

  const onRowClicked = useMemo(() => {
    return (event: RowClickedEvent<PageContent>) => {
      const { _id: id } = event.node.data!;
      // const search = getContentSearchParams(queryParams).join("&");
      navigate({
        pathname: `/content/item/${id}`,
        // search,
      });
    };
  }, [navigate]);

  const contentTable = useMemo(() => {
    return (
      <DefaultGrid<PageContent>
        rowId={(params) => params.data._id ?? ""}
        datasource={contentMgmtServerSideDatasource}
        columnDefs={CMColDefs}
        onRowClicked={onRowClicked}
        noRowsFoundText="No Content Found Matching Filters"
        ref={gridRef}
      />
    );
  }, [contentMgmtServerSideDatasource, onRowClicked]);

  const render = () => (
    <>
      {renderActionBar()}
      <div className="flex pl-8 pt-4 ml-4 justify-between items-center">
        <button
          type="button"
          className="flex place-content-start w-1/4"
          onClick={handleCheckboxListToggle}
        >
          <div className="self-center justify-items-start bg-blue w-[24px] h-[24px] rounded-full">
            <img
              src={filterSVG as string}
              alt="Filter"
              className="bg-blue inline-block rounded-full w-[18px] h-[18px]"
            />
          </div>
          <p className="inline-block text-blue px-4">Customize Column View</p>
        </button>
      </div>
      <div className="pl-8 justify-between items-center">
        {isColumnFilterOpen && gridRef.current && (
          <ColumnCustomizeFilter
            gridApi={gridRef.current.api}
            onClickOutside={() => setIsColumnFilterOpen(false)}
          />
        )}
      </div>
      <div className="pl-8 pr-4 flex-grow self-center flex w-full box-border relative">
        <div
          className="ag-theme-quartz overflow-x-auto w-full h-full md:p-4 md:h-auto flex-grow" // applying the Data Grid theme
        >
          {contentTable}
        </div>
      </div>
      <div id="main-wrapper" className="px-8 py-4 w-full text-left relative" />
    </>
  );

  return render();
}
