import Axios, { AxiosError, AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";
import pkceChallenge from "pkce-challenge";
import Logger from "../common/Logger";
import AppSettingsService from "./AppSettingsService";
import OidcMetadata from "./interface/oauth/OidcMetadata";
import TokenResponse from "./interface/oauth/TokenResponse";
import UserInfo from "./interface/oauth/UserInfo";

export default class AuthApi {
  private static oidcMetadata?: OidcMetadata;

  public static async getTokens(): Promise<TokenResponse | null> {
    // Attempt to get code verifier that was saved during the login attempt.
    const codeVerifier = sessionStorage.getItem("cv");
    sessionStorage.removeItem("cv");
    if (codeVerifier) {
      Logger.log("Using code challenge to get tokens");

      // check for authorization code in query params.
      const newUrl = new URL(window.location.href);
      const authorizationCode: string | null = newUrl.searchParams.get("code");
      newUrl.searchParams.delete("code");
      window.history.replaceState({}, "", newUrl.href);

      if (authorizationCode) {
        return await AuthApi.getTokensUsingChallenge(
          authorizationCode,
          codeVerifier,
        );
      }
    }

    const refresh_token = sessionStorage.getItem("refresh_token");
    if (refresh_token) {
      Logger.log("Using refresh tokens to get tokens");
      return await AuthApi.getTokensUsingRefreshToken(
        refresh_token,
      );
    }

    return null;
  }

  /**
   * Attempts to get the UserInfo object.
   * - If no access_token is present, it checks for an authorization code and attempts to exchange it
   *   for an access token and then calls the introspection endpoint.
   * - If an access_token is present, it calls the oauth introspection endpoint.
   * - If no access_token or authorization_code is present, it returns null.
   */
  public static async getUserInfo(authTokens: TokenResponse): Promise<UserInfo | null> {
    // // Attempt to get code verifier that was saved during the login attempt.
    // const codeVerifier = sessionStorage.getItem("cv");
    // sessionStorage.removeItem("cv");
    // if (codeVerifier) {
    //   // check for authorization code in query params.
    //   const newUrl = new URL(window.location.href);
    //   const authorizationCode: string | null = newUrl.searchParams.get("code");
    //   newUrl.searchParams.delete("code");
    //   window.history.replaceState({}, "", newUrl.href);
    //
    //   if (authorizationCode) {
    //     const tokenResponse = await AuthApi.getTokensUsingChallenge(
    //       authorizationCode,
    //       codeVerifier,
    //     );
    //     AuthApi.updateValues(tokenResponse);
    //   }
    // }

    // Checks for an access token, which may have been generated above or already present.
    if (authTokens.access_token) {
      // User is logged in. Attempt to introspect credentials.
      let userInfo = await AuthApi.introspect();
      if (userInfo) {
        return userInfo;
      }
    }

    // // If we failed to get the user info with the access token, try refreshing the token
    // const refresh_token = sessionStorage.getItem("refresh_token");
    // if (refresh_token) {
    //   const refreshResponse = await AuthApi.getTokensUsingRefreshToken(
    //     refresh_token,
    //   );
    //
    //   AuthApi.updateValues(refreshResponse);
    //   return AuthApi.introspect();
    // }

    return null;
  }

  /**
   * Authorize the client
   *
   * @return An authorization code that cam be used to obtain an access token.
   */
  public static async authorize() {
    const oidcMetadata = await AuthApi.getOidcMetadata();
    let endpoint = oidcMetadata.authorization_endpoint;
    if (!endpoint) {
      // TODO: Redirect to error page. "Client configuration error"
      return Promise.resolve();
    }

    const codeChallenge = await AuthApi.createChallenge();
    const currentUrl = window.location.href.split("?")[0];
    const params = new URLSearchParams({
      response_type: "code",
      scope: "openid email profile",
      code_challenge_method: "S256",
      code_challenge: codeChallenge,
      client_id: AppSettingsService.getPingClientId(),
      redirect_uri: currentUrl,
    });

    const location: string = endpoint + "?" + params.toString();
    Logger.logInfo(`Should do auth login at: ${location}`);
    window.location.href = location;
    return Promise.resolve();
  }

  /**
   * Exchange the user authorization token for access_token.
   * @param challengeCode
   * @param codeVerifier
   */
  public static async getTokensUsingChallenge(challengeCode: string, codeVerifier: string) {
    const currentUrl = window.location.href.split("?")[0];

    const options: any = {
      code: challengeCode,
      grant_type: `authorization_code`,
      client_id: AppSettingsService.getPingClientId(),
      code_verifier: codeVerifier,
      redirect_uri: currentUrl,
    };

    return AuthApi.getTokenWithOptions(options);
  }

  /**
   * Exchange the user authorization token for access_token.
   */
  public static async getTokensUsingRefreshToken(refreshToken: string | null) {
    const options: any = {
      grant_type: `refresh_token`,
      client_id: AppSettingsService.getPingClientId(),
      refresh_token: refreshToken,
    };

    return AuthApi.getTokenWithOptions(options);
  }

  private static async getTokenWithOptions(options: any): Promise<TokenResponse | null> {
    const oidcMetadata = await AuthApi.getOidcMetadata();
    const endpoint = oidcMetadata.token_endpoint;

    const clientId = btoa(`${AppSettingsService.getPingClientId()}:`);

    const headers = {
      Authorization: `Basic ${clientId}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const server = Axios.create({
      headers,
    });

    try {
      const response = await server
        .post<TokenResponse>(endpoint, options, { headers });
      return response.data;
    } catch (error) {
      sessionStorage.clear();
      sessionStorage.setItem("errorTitle", "Unable to authenticate.");
      sessionStorage.setItem(
        "errorMessage",
        "There was a problem authenticating with the server.");
      if (error instanceof AxiosError) {
        sessionStorage.setItem(
          "errorDetails",
          error.response?.data.error.error_description ?? "No error response found");
      }
    }

    return null;
  }

  /**
   * Returns introspection data fetched from PingFed by the BFF.
   * This shouldn't need any parameters, as the Bearer token should be set by middleware.
   */
  private static async introspect() {
    const endpoint: string = "/auth/introspect";
    return Axios.post<UserInfo>(endpoint).then(
      (axiosResponse) => {
        return axiosResponse.data;
      },
    ).catch((e) => {
      Logger.logError(`Login failed. Code ${e?.response?.status}`, e);
      return null;
    });
  }

  public static async logout() {
    const oidcMetadata = await AuthApi.getOidcMetadata();

    localStorage.clear();
    sessionStorage.clear();

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name).then();
      });
    });

    const location: string =
      oidcMetadata.ping_end_session_endpoint ??
      oidcMetadata.end_session_endpoint ??
      window.location.origin;
    Logger.logInfo(`Logging off at: ${location}`);
    window.location.href = location;
  }

  /**
   * Read the oidc metadata to get links to the oauth configuration.
   * @protected
   */
  protected static async getOidcMetadata() {
    const openIdConfigurationEndpoint = AppSettingsService.getOpenIdConfigUrl();
    if (!AuthApi.oidcMetadata && openIdConfigurationEndpoint) {
      const oidcSettings = Axios.create();
      const response: AxiosResponse<OidcMetadata> = await oidcSettings.get(
        openIdConfigurationEndpoint,
      );
      AuthApi.oidcMetadata = response.data;
    }
    return AuthApi.oidcMetadata!;
  }

  /**
   * Creates a PKCE challenge code and chalenge code verifier.
   * The verifier is stored in session storage and the challenge code is
   * returned by the function.
   */
  private static async createChallenge(): Promise<string> {
    const pkce = await pkceChallenge();
    sessionStorage.setItem("cv", pkce.code_verifier);
    return pkce.code_challenge;
  }

  private static updateValues(data: TokenResponse | null): UserInfo | null {
    if (data) {
      sessionStorage.setItem("access_token", data.access_token);
      sessionStorage.setItem("id_token", data.id_token!);
      sessionStorage.setItem("refresh_token", data.refresh_token!);
      Axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
      return jwtDecode(data.id_token!);
    }
    Axios.defaults.headers.common.Authorization = '';
    sessionStorage.removeItem("cv");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("refresh_token");
    return null;
  }
}
