import ItemData from "../../api/interface/cdh/ItemData";

export interface ItemInfoProps {
  data: ItemData;
}

const ItemInfo = (props: ItemInfoProps) => {
  const { data } = props;
  const { description, secondaryDescription, id, bbaId, itemThumbnail } = data;
  const itemTitle: string = description ?? "Unknown Item";
  const itemSubtitle = secondaryDescription ?? "Unknown";
  const itemId = id;

  const apNumber: string = String(bbaId?.apNumber ?? "-");
  const bbaNumber: string = String(bbaId?.bbaNumber ?? "-");

  const vendorName = bbaId?.name ?? "Unknown Vendor";

  return (
    <div className="flex mt-4 h-auto w-auto">
      {itemThumbnail && (
        <img
          alt="Item thumbnail"
          className="h-48 object-contain mr-12"
          src={itemThumbnail}
        />
      )}
      <div className="pt-2 text-gray-700">
        <div className="font-semibold text-xl">{itemTitle}</div>
        <div className="text-base font-light">{itemSubtitle}</div>
        <div className="text-sm pt-4 grid grid-cols-2 gap-x-8">
          <span>Item ID</span>
          <span>{itemId}</span>
          <span>AP Vendor Number</span>
          <span>{apNumber}</span>
          <span>Vendor Name</span>
          <span>{vendorName}</span>
          <span>BBA Vendor Number</span>
          <span>{bbaNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default ItemInfo;
