import Axios from "axios";
import { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import AppSettingsService from "../api/AppSettingsService";
import Logger from "../common/Logger";
import useAuth from "../hooks/useAuth";

const SwaggerPage = () => {
  const { authTokens } = useAuth();
  const [swaggerSpec, setSwaggerSpec] = useState(null);

  useEffect(() => {
    // Fetch the Swagger YAML file from your backend
    const fetchSwaggerSpec = async () => {
      try {
        const response = await Axios.get("/status/swagger-definition");
        setSwaggerSpec(response.data);
      } catch (error) {
        Logger.logError("Failed to fetch Swagger spec:", error);
      }
    };

    fetchSwaggerSpec();
  }, []);

  if (!swaggerSpec) {
    return <div>Loading Swagger documentation...</div>;
  }

  return (
    <SwaggerUI
      spec={swaggerSpec}
      tryItOutEnabled
      requestInterceptor={(req) => {
        // Inject Bearer token into the request
        if (
          authTokens?.access_token &&
          req.url.includes(AppSettingsService.getBaseUrl())
        ) {
          req.headers.Authorization = `Bearer ${authTokens.access_token}`;
        }
        return req;
      }}
    />
  );
};

export default SwaggerPage;
