import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  generateQueryParams,
  generateContentQueryParams,
  getSearchParams,
  getContentSearchParams,
} from "../../util/Helper";
import QueryParams from "../../api/interface/QueryParams";
import searchSVG from "../../images/svg/search.svg";
import clearSVG from "../../images/svg/clear.svg";

const SearchBar = ({
  value,
  placeholder,
  setValue,
  searchType,
}: {
  value: string;
  placeholder?: string;
  setValue: (value: string) => void;
  searchType: string;
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(value);
  const queryParams: QueryParams = useMemo(
    () =>
      searchType === "trainingAndEducation"
        ? generateContentQueryParams(searchParams)
        : generateQueryParams(searchParams),
    [searchParams, searchType],
  );
  const navigate = useNavigate();
  const handleClearSearch = useCallback(() => {
    if (searchType === "items") {
      localStorage.removeItem("ItemSearchQuery");
    }
    if (searchType === "bba") {
      localStorage.removeItem("ChildSearchQuery");
    }
    setSearchInput("");
    setValue("");
    navigate({
      pathname: location.pathname,
    });
  }, [location.pathname, navigate, searchType, setValue]);

  const updateSearchParams = useCallback(() => {
    const search: string =
      searchType === "trainingAndEducation"
        ? getContentSearchParams(queryParams).join("&")
        : getSearchParams(queryParams).join("&");
    if (search !== location.search) {
      if (!searchInput) {
        handleClearSearch();
      } else {
        navigate({
          pathname: location.pathname,
          search,
        });
      }
    }
    if (queryParams.query) {
      setValue(queryParams.query);
      if (searchType === "items") {
        localStorage.setItem("ItemSearchQuery", search);
      }
      if (searchType === "bba") {
        localStorage.setItem("ChildSearchQuery", search);
      }
    }
  }, [
    queryParams,
    location.search,
    location.pathname,
    searchInput,
    handleClearSearch,
    navigate,
    setValue,
    searchType,
  ]);

  const doSearch = () => {
    queryParams.query = searchInput;
    updateSearchParams();
  };

  return (
    <div className="relative w-full">
      <input
        type="search"
        value={searchInput}
        id="search-dropdown"
        className="z-20 block w-full rounded border border-s-2 border-gray-300 border-s-gray-50 bg-gray-50 p-2.5 text-sm text-gray-900 focus:outline-none focus:ring-0"
        placeholder={placeholder}
        required
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            doSearch();
          }
        }}
      />
      {searchInput && (
        <button
          type="button"
          className="absolute end-[60px] top-[13px] text-gray-light"
          onClick={handleClearSearch}
        >
          <img src={clearSVG as string} alt="clear search button" />
        </button>
      )}
      <button
        type="submit"
        className="absolute end-0 top-0 h-full rounded-e-lg border border-blue-700 bg-blue-700 p-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
        onClick={doSearch}
      >
        <img src={searchSVG as string} alt="seachIcon" />
        <span className="sr-only">Search</span>
      </button>
    </div>
  );
};

export default SearchBar;
