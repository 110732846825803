import svgLoading from "../../images/svg/loading.svg";

const CostcoBlueLoader = () => (
  <div className="flex flex-grow items-center justify-center z-10">
    <div className="flex flex-col items-center">
      <div className="w-16 h-16">
        <img src={svgLoading as string} alt="loading..." />
      </div>
    </div>
  </div>
);

export default CostcoBlueLoader;
