import Axios, { InternalAxiosRequestConfig } from "axios";
import Logger from "../common/Logger";
import AppSettingsService from "./AppSettingsService";

export default class ApiSettings {
  private static instance: ApiSettings | undefined;

  private constructor() {}

  public static getBaseUrl() {
    return AppSettingsService.getBaseUrl();
  }

  public static initialize() {
    return ApiSettings.getInstance();
  }

  public static reset() {
    ApiSettings.instance = undefined;
    Axios.interceptors.request.clear();
  }

  public static getInstance() {
    if (!ApiSettings.instance) {
      ApiSettings.instance = new ApiSettings();
      ApiSettings.instance.configure();
    }
    return ApiSettings.instance;
  }

  /** The request logging interceptor very minimally logs all requests made client side. */
  private static requestLoggingInterceptor(
    request: InternalAxiosRequestConfig,
  ) {
    Logger.logInfo(
      `[${request.method!.toUpperCase()}] ${request.baseURL}${request.url}`,
    );
    return request;
  }

  private configure() {
    Axios.defaults.baseURL = ApiSettings.getBaseUrl();
    Logger.logInfo(`Processing requests from: ${ApiSettings.getBaseUrl()}`);

    // Add request logging interceptor.
    Logger.logInfo("Logging all axios requests.");
    Axios.interceptors.request.use(ApiSettings.requestLoggingInterceptor);
  }
}
