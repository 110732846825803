import ContentStatus from "../../../api/interface/ContentStatus";
import PageContent from "../../../api/interface/PageContent";

const ContentStatusChip = (content: PageContent) => {
  let colorStyle: string = "";
  let statusText = "";
  const { publishDate, status } = content;
  switch (status) {
    case ContentStatus.DRAFT:
      statusText = "Draft";
      colorStyle = " bg-caution-background text-caution-text ";
      break;
    case ContentStatus.ARCHIVE:
      statusText = "Archive";
      colorStyle = " bg-gray-300 text-gray-700 ";
      break;
    case ContentStatus.ACTIVE:
      if (!publishDate) {
        statusText = "Draft";
        colorStyle = " bg-alert-background text-alert-text ";
      } else if (publishDate < new Date()) {
        statusText = "Live";
        colorStyle = " bg-success-background text-success-text ";
      } else {
        statusText = "Scheduled";
        colorStyle = " bg-blue-600 text-white ";
      }
      break;
    default:
  }

  return (
    <span
      className={[
        "items-center font-sans font-semibold uppercase whitespace-nowrap select-none py-1 px-4 text-xs rounded-md",
        colorStyle,
      ].join(" ")}
    >
      {statusText}
    </span>
  );
};

const ContentThumbnail = (content: PageContent) => {
  const { image, title } = content;
  return <img src={image} alt={title} />;
};

const ContentThumbnailTooltip = (content: PageContent) => {
  return <div className="max-w-50">{ContentThumbnail(content)}</div>;
};

export { ContentThumbnailTooltip, ContentStatusChip, ContentThumbnail };
