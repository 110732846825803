import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { ColDef, ColGroupDef, RowClickedEvent } from "ag-grid-community";
import { Tooltip } from "react-tooltip";
import { AgGridReact } from "ag-grid-react";
import { generateQueryParams } from "../util/Helper";
import QueryParams from "../api/interface/QueryParams";
import DefaultGrid from "../components/tables/DefaultGrid";
import ItemServerSideDatasource from "../api/GridDataSources/ItemServerSideDataSource";
import ItemData from "../api/interface/cdh/ItemData";
import SearchBar from "../components/common/SearchBar";
import {
  BBAVendorItemTableColumns,
  CategoriesColDefs,
  CertificationsColDefs,
  CommoditiesColDefs,
  DepartmentColDef,
  DescriptionColDefsForCostcoUsers,
  DescriptionColDefsForVendorUsers,
  OrganicColDef,
  PalmOilColDef,
  VendorBBAColDef,
  VendorNameColDef,
} from "./VendorDetailsPage/VendorDetailsProperties/VendorDetailsPageColumnDefinitions";
import ItemDetailsSection from "../components/sections/ItemDetailsSection";
import svgExclamation from "../images/svg/exclamation.svg";
import useAuth from "../hooks/useAuth";
import PermissionService from "../permissions/PermissionService";
import ColumnCustomizeFilter from "../components/common/ColumnCustomizeFilter";
import filterSVG from "../images/svg/filter.svg";
import SearchErrorPrompt from "../components/common/SearchErrorPrompt";

export default function ItemSearchPage(): ReactElement {
  const [searchParams] = useSearchParams();
  const [searchError, setSearchError] = useState(false);
  const queryParams: QueryParams = useMemo(
    () => generateQueryParams(searchParams),
    [searchParams],
  );
  const { user } = useAuth();
  const gridRef = useRef<AgGridReact<ItemData>>(null);
  const isVendorUser = PermissionService.isVendorUser(user);
  const [isOpen, setIsOpen] = useState(false);

  const [showItemDetails, setShowItemDetails] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemData>();

  const itemServerSideDatasource = useMemo(() => {
    const dataSource = new ItemServerSideDatasource(gridRef);
    dataSource.searchText = queryParams.query ?? "";
    return dataSource;
  }, [queryParams]);

  /** Checks for any pending messages to display toast messages. */
  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  const handleCheckboxListToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseError = () => {
    setSearchError(false);
  };
  const renderSearch = () => {
    return (
      <div className="w-full shrink pl-8 pr-4 lg:min-w-[996px] md:min-w-[650px] sm:min-w-[500px] xs:min-w-[400px] mt-4">
        <div className="pb-4">
          <div className="font-medium text-lg inline-flex">Search By</div>
          <img
            id="item-rolodex-search-by-tooltip"
            src={svgExclamation as string}
            className="inline-flex pl-4 pb-1 text-gray-dark"
            alt="exclamation icon"
          />
          <Tooltip
            anchorSelect="#item-rolodex-search-by-tooltip"
            className="text-sm"
            place="right"
          >
            Search by Item Number or Description
          </Tooltip>
          {searchError && (
            <div className="w-4/5 p-2">
              <SearchErrorPrompt dismiss={handleCloseError} />
            </div>
          )}
        </div>
        <div className="flex w-full justify-between items-center">
          <SearchBar
            value={queryParams.query ?? ""}
            setValue={() => {}}
            searchType="itemSearchPage"
            placeholder="Search by Item Number or Description"
          />
        </div>
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
        />
      </div>
    );
  };

  const renderActionBar = () => {
    return (
      <div
        id="actionBar"
        className="flex flex-col py-4 flex-none border-b bg-white shadow"
      >
        <div className="flex pl-8 mb-3 min-w-full">
          <p className="text-3xl no-wrap">Item Search</p>
        </div>
        <div className="flex">{renderSearch()}</div>
      </div>
    );
  };

  const itemSelection = (item?: ItemData) => {
    setSelectedItem(item);
    setShowItemDetails(item !== undefined);
  };

  const onItemRowClicked = useCallback((event: RowClickedEvent<ItemData>) => {
    const itemClicked = event.data;
    itemSelection(itemClicked);
  }, []);

  const getItemProfileDefs = useCallback(() => {
    const userDefs: (ColDef<ItemData> | ColGroupDef<ItemData>)[] = isVendorUser
      ? [DescriptionColDefsForVendorUsers]
      : [DescriptionColDefsForCostcoUsers, CategoriesColDefs];

    const defaultDefs: (ColDef<ItemData> | ColGroupDef<ItemData>)[] = [
      DepartmentColDef,
      VendorBBAColDef,
      VendorNameColDef,
      ...userDefs,
      CertificationsColDefs,
      CommoditiesColDefs,
      OrganicColDef,
      PalmOilColDef,
    ];

    return [...BBAVendorItemTableColumns, ...defaultDefs];
  }, [isVendorUser]);

  const renderGrid = useMemo(() => {
    return (
      <DefaultGrid<ItemData>
        rowId={(params) => params.data._id ?? ""}
        datasource={itemServerSideDatasource}
        columnDefs={getItemProfileDefs()}
        onRowClicked={onItemRowClicked}
        noRowsFoundText="No Items Found"
        ref={gridRef}
      />
    );
  }, [getItemProfileDefs, itemServerSideDatasource, onItemRowClicked]);

  const onItemClose = () => {
    setShowItemDetails(false);
  };

  const render = () => (
    <>
      {renderActionBar()}
      <div className="flex pl-8 pt-4 justify-between items-center">
        <button
          type="button"
          className="flex place-content-start w-1/4"
          onClick={handleCheckboxListToggle}
        >
          <div className="self-center justify-items-start bg-blue w-[24px] h-[24px] rounded-full">
            <img
              src={filterSVG as string}
              alt="Filter"
              className="bg-blue inline-block rounded-full w-[18px] h-[18px]"
            />
          </div>
          <p className="inline-block text-blue px-4">Customize Column View</p>
        </button>
      </div>
      <div className="pl-8 justify-between items-center">
        {isOpen && gridRef.current && (
          <ColumnCustomizeFilter
            gridApi={gridRef.current.api}
            onClickOutside={() => setIsOpen(false)}
          />
        )}
      </div>
      <div className="flex-grow self-center flex w-full box-border relative">
        <div
          className="ag-theme-quartz overflow-x-auto w-full h-full md:p-4 md:h-auto flex-grow" // applying the Data Grid theme
        >
          {renderGrid}
        </div>
      </div>
      <div id="main-wrapper" className="px-8 py-4 w-full text-left relative" />
      <ItemDetailsSection
        visible={showItemDetails}
        item={selectedItem}
        closeFunction={onItemClose}
      />
    </>
  );
  return render();
}
