import { useCallback } from "react";
import { useGridFilter } from "ag-grid-react";
import type { IDoesFilterPassParams } from "ag-grid-community";
import type { CustomFilterProps } from "ag-grid-react";
import { checkboxDefaultStyle } from "../../../components/common/CheckBox";

const sections = [
  { name: "Policies & Guidelines", value: "policies-and-guidelines" },
  { name: "Tools & Templates", value: "tools-and-templates" },
  { name: "Job Aids", value: "job-aids" },
  { name: "Videos", value: "videos" },
]; // Available sections

const SectionFilter = ({
  model,
  onModelChange,
}: CustomFilterProps<never, never, string[]>) => {
  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      if (model === null) return true;
      if (model.length === 0) return true;
      return model.includes(params.data.section);
    },
    [model],
  );

  useGridFilter({
    doesFilterPass,
  });

  const onSectionChange = (section: string) => {
    if (model === null) {
      onModelChange([section]);
    } else {
      const newModel = model.includes(section)
        ? model.filter((s) => s !== section)
        : [...model, section];

      onModelChange(newModel.length > 0 ? newModel : null);
    }
  };

  const checkboxes = sections.map((section) => (
    <label key={section.name} className="items-center w-full text-gray-900">
      <input
        type="checkbox"
        className={checkboxDefaultStyle}
        defaultChecked={model?.includes(section.value)}
        onClick={() => onSectionChange(section.value)}
      />
      {section.name}
    </label>
  ));

  return (
    <div className="p-2">
      <strong>Filter by Section</strong>
      <div className="flex flex-col pt-1 pb-1 pr-1">{checkboxes}</div>
    </div>
  );
};

export default SectionFilter;
