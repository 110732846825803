import { ReactElement, useEffect, useRef } from "react";
import PageContent from "../../api/interface/PageContent";

const VideoContent = (props: {
  item: PageContent;
  onClose: () => void;
}): ReactElement => {
  const { item, onClose } = props;
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, onClose]);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black/60 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="relative bg-white p-6 shadow-xl sm:mx-auto sm:px-10"
      >
        <button
          type="button"
          className="absolute top-2 right-2 text-gray-950 cursor-pointer"
          onClick={onClose}
        >
          <span className="material-icons-outlined">close</span>
        </button>
        <h2 className="text-lg text-gray-800 font-semibold mb-2">
          {item.title}
        </h2>
        <div>
          <label>{item.subtitle}</label>
        </div>
        <div className="mt-6">
          {/* TODO: Add captions to video upload conditions */}
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video className="h-96 w-full" controls controlsList="nodownload">
            <source src={item.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoContent;
