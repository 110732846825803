import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import ContentType from "../../../api/interface/ContentType";
import PageContent from "../../../api/interface/PageContent";
import SectionFilter from "../../TrainingAndEducationPage/TrainingAndEducationProperties/SectionFilter";
import { ContentStatusChip, ContentThumbnail, ContentThumbnailTooltip } from "./ContentCellRenderers";
import ContentTypeFilter from "./ContentTypeFilter";

export const CMColDefs: (ColDef<PageContent> | ColGroupDef<PageContent>)[] = [
    {
        headerName: "Thumbnail",
        field: "image",
        filter: false,
        tooltipField: "image",
        cellRenderer: (params: CustomCellRendererProps<PageContent>) => {
            if (params.data) {
                return ContentThumbnail(params.data);
            }
            return null;
        },
        tooltipComponent: (params: CustomCellRendererProps<PageContent>) => {
            if (params.data) {
                return ContentThumbnailTooltip(params.data);
            }
            return null;
        },
        flex: 1
    },
    {
        headerName: "Title",
        field: "title",
        tooltipField: "title",
        filter: true,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 2
    },
    {
        headerName: "Description",
        field: "subtitle",
        tooltipField: "subtitle",
        filter: true,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
    {
        headerName: "Author",
        field: "author",
        filter: true,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
    {
        headerName: "Type",
        field: "type",
        valueFormatter: (content) => {
            switch (content.value) {
                case ContentType.HERO:
                    return "Hero";
                case ContentType.DOCUMENTS:
                    return "Training & Education";
                case ContentType.NEWS:
                    return "News";
                default:
                    return "";

            }
        },
        filter: ContentTypeFilter,
        flex: 1,
    },
    {
        headerName: "Section",
        field: "section",
        valueFormatter: (content) => {
            switch (content.value) {
                case "policies-and-guidelines":
                    return "Policies & Guidelines";
                case "tools-and-templates":
                    return "Tools & Templates";
                case "job-aids":
                    return "Job Aids";
                case "videos":
                    return "Videos";
                default:
                    return "";

            }
        },
        filter: SectionFilter,
        flex: 1,
    },
    {
        headerName: "Status",
        field: "status",
        filter: true,
        cellRenderer: (params: CustomCellRendererProps<PageContent>) => {
            if (params.data) {
                return ContentStatusChip(params.data);
            }
            return null;
        },
    },
    {
        headerName: "Publication Date",
        field: "publishDate",
        filter: "agDateColumnFilter",
        valueFormatter: (params) => {
            const pageContent = params.data;
            return pageContent?.publishDate?.toLocaleDateString() ?? "";
        },
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
    {
        headerName: "Last Modified",
        field: "updatedAt",
        filter: "agDateColumnFilter",
        sort: "desc",
        hide: true,
        valueFormatter: (params) => {
            const pageContent = params.data;
            return pageContent?.updatedAt?.toLocaleDateString() ?? "";
        },
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    },
    {
        headerName: "Modified By",
        field: "modifiedBy",
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: {
            maxNumConditions: 1
        },
        flex: 1,
    }
];

