import { ReactElement } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AppSettingsService from "./api/AppSettingsService";
import useAuth from "./hooks/useAuth";
import DefaultLayout from "./layout/DefaultLayout";
import ContentManagementPage from "./pages/ContentManagementPage/ContentManagementPage";
import ContentResetPage from "./pages/ContentResetPage";
import HomePage from "./pages/HomePage";
import ItemSearchPage from "./pages/ItemSearchPage";

import LogoutPage from "./pages/LogoutPage";
import NoAccessPage from "./pages/NoAccessPage";
import NotFoundPage from "./pages/NotFoundPage";
import SwaggerPage from "./pages/SwaggerPage";
import TrainingAndEducationPage from "./pages/TrainingAndEducationPage/TrainingAndEducationPage";
import VendorDetailsPage from "./pages/VendorDetailsPage/VendorDetailsPage";
import VendorSearchPage from "./pages/VendorSearchPage";
import Role, { VendorSearchRoles } from "./permissions/interface/Role";

import PermissionService from "./permissions/PermissionService";

const Router = (): ReactElement => {
  const { user } = useAuth();

  const getDebugRoutes = () => {
    const isCostcoUser = PermissionService.hasPermissionLevel(
      user,
      Role.COSTCO_USER,
    );
    const isProduction = AppSettingsService.getCostcoEnvironment() === "prd";
    return !isProduction && isCostcoUser ? (
      <Route path="/swagger" element={<SwaggerPage />} />
    ) : undefined;
  };

  const getAdminRoutes = () => {
    return (
      <>
        <Route
          path="/content/reset"
          element={
            PermissionService.hasPermissionLevel(
              user,
              Role.COSTCO_CONTENTMGMT_ADMIN,
            ) ? (
              <ContentResetPage />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          path="/items"
          element={
            PermissionService.hasPermissionLevel(
              user,
              Role.COSTCO_ITEMROLODEX_READONLY,
              Role.VENDOR_ADMIN,
              Role.VENDOR_ESG_LEAD,
            ) ? (
              <ItemSearchPage />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          path="/vendors"
          element={
            PermissionService.hasPermissionLevel(user, ...VendorSearchRoles) ? (
              <VendorSearchPage />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          path="/vendor/:sapNumber/:bbaNumber?/:section"
          element={<VendorDetailsPage />}
        />
      </>
    );
  };

  const getManagementRoutes = () => {
    const hasManagementPermissions = PermissionService.hasCreatorRole(user);
    if (!hasManagementPermissions) {
      return (
        <>
          <Route path="/content" element={<NotFoundPage />} />;
          <Route path="/content/item/:id" element={<NotFoundPage />} />
        </>
      );
    }
    return <Route path="/content" element={<ContentManagementPage />} />;
  };

  const getUserRoutes = () => {
    const hasHomepagePermissions = PermissionService.hasPermissionLevel(
      user,
      ...Object.values(Role),
    );

    return (
      <Route>
        <Route
          path="/"
          element={hasHomepagePermissions ? <HomePage /> : <NoAccessPage />}
        />
        <Route
          path="/training-and-education"
          element={<TrainingAndEducationPage />}
        />
        <Route
          path="/t&e"
          element={<Navigate to="/training-and-education" />}
        />
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/logout" element={<LogoutPage />} />
          {getDebugRoutes()}
          {getAdminRoutes()}
          {getManagementRoutes()}
          {getUserRoutes()}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
