import {
  ColDef,
  ColGroupDef,
  FilterChangedEvent,
  GetRowIdFunc,
  GridReadyEvent,
  IDatasource,
  RowClickedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, ReactNode, Ref } from "react";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_SELECTOR,
} from "../../pages/VendorDetailsPage/VendorDetailsProperties/ConstantsGlossary";
import CostcoBlueLoader from "../common/LoadingIndicators";

type DefaultGridProps<T> = {
  rowId: GetRowIdFunc<T>;
  datasource?: IDatasource;
  columnDefs: (ColDef<T> | ColGroupDef<T>)[];
  rowData?: T[];
  loading?: boolean;
  onSortChanged?: (event: SortChangedEvent<T>) => void;
  onFilterChanged?: (event: FilterChangedEvent<T>) => void;
  onRowClicked?: (event: RowClickedEvent<T>) => void;
  onGridReady?: (event: GridReadyEvent<T>) => void;
  noRowsFoundText?: string;
  quickFilterText?: string;
};

const DefaultGridInner = <T,>(
  {
    columnDefs,
    rowData,
    datasource,
    loading,
    onSortChanged,
    onFilterChanged,
    onRowClicked,
    rowId,
    noRowsFoundText,
    onGridReady,
    quickFilterText,
  }: DefaultGridProps<T>,
  ref: Ref<AgGridReact<T>>,
): ReactNode => {
  const loadingOverlay = () => <CostcoBlueLoader />;
  const modelType = datasource ? "infinite" : "clientSide";
  return (
    <AgGridReact<T>
      getRowId={rowId}
      rowModelType={modelType}
      rowData={rowData}
      datasource={datasource ?? undefined}
      domLayout="autoHeight"
      animateRows
      columnDefs={columnDefs}
      cacheBlockSize={datasource ? 20 : undefined}
      infiniteInitialRowCount={DEFAULT_PAGE_SIZE}
      defaultCsvExportParams={{}}
      defaultColDef={{
        headerClass: "defaultTableHeader",
        cellClass: "defaultCell",
      }}
      loading={loading}
      pagination
      paginationPageSize={DEFAULT_PAGE_SIZE}
      paginationPageSizeSelector={DEFAULT_PAGE_SIZE_SELECTOR}
      valueCache
      // rowSelection={{ mode: "singleRow", checkboxes: false }}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      onRowClicked={onRowClicked}
      columnHoverHighlight
      quickFilterText={quickFilterText}
      className="ag-theme-quartz h-96"
      loadingOverlayComponent={loadingOverlay}
      overlayNoRowsTemplate={noRowsFoundText}
      onGridReady={onGridReady}
      ref={ref} // Forward the ref here
    />
  );
};

// Wrap the component with forwardRef
const DefaultGrid = forwardRef(DefaultGridInner) as <T>(
  props: DefaultGridProps<T> & { ref?: Ref<AgGridReact<T>> },
) => ReactNode;

export default DefaultGrid;
