import { ReactElement } from "react";

import { v4 } from "uuid";
import ContentStatus from "../../api/interface/ContentStatus";
import ContentType from "../../api/interface/ContentType";
import PageContent from "../../api/interface/PageContent";

export default function ArchiveSection(props: {
  items?: PageContent[];
}): ReactElement {
  const title: string = "Commitment Archive";
  const disclosure: string =
    "We provide historical versions of the Commitment here only as a matter of convenience to users, who may wish to compare them over time. These historical Commitments are superseded in their entirety by the current version of the Commitment and should not be relied upon as currently accurate disclosures or otherwise.";

  const commitments: PageContent[] = [
    {
      _id: v4(),
      date: new Date("2022-01-01"),
      title: "2022 Sustainability Commitment",
      link: "https://mobilecontent.costco.com/live/resource/img/23w10059/7-ArchivedCommitment2022.pdf",
      status: ContentStatus.ACTIVE,
      type: ContentType.DOCUMENTS,
    },
    {
      _id: v4(),
      date: new Date("2022-01-01"),
      title: "2021 Sustainability Commitment",
      link: "https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/sust_2021_final.pdf",
      status: ContentStatus.ACTIVE,
      type: ContentType.DOCUMENTS,
    },
    {
      _id: v4(),
      date: new Date("2022-01-01"),
      title: "2020 Sustainability Commitment",
      link: "https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/2020_Sustainability_Archive.pdf",
      status: ContentStatus.ACTIVE,
      type: ContentType.DOCUMENTS,
    },
    {
      _id: v4(),
      date: new Date("2022-01-01"),
      title: "2019 Sustainability Commitment",
      link: "https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/2019_Sustainability_Archive.pdf",
      status: ContentStatus.ACTIVE,
      type: ContentType.DOCUMENTS,
    },
    {
      _id: v4(),
      date: new Date("2022-01-01"),
      title: "2018 Sustainability Commitment",
      link: "https://mobilecontent.costco.com/live/resource/img/sustainability-archive/2018-sustainability-archive.pdf",
      status: ContentStatus.ACTIVE,
      type: ContentType.DOCUMENTS,
    },
  ];

  const render = () => {
    const items =
      props.items && props.items.length > 0 ? props.items : commitments;
    return (
      <div className="w-full py-4 bg-white">
        <div className="mx-auto max-w-2xl">
          <div className="mx-auto text-center text-xl font-semibold text-blue-900">
            {title}
          </div>
          <div className="p-4 pb-1 text-gray-900">{disclosure}</div>
          <div className="grid p-4 py-2 mb-4 text-blue underline md:grid-cols-2">
            {items.map((item) => (
              <a
                key={item._id}
                className="block mb-1"
                target="_blank"
                href={item.link}
                rel="noreferrer"
              >
                {item.title}
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return render();
}
