import QueryParams from "../api/interface/QueryParams";

export const generateQueryParams = (params: URLSearchParams, cleanQuery?:boolean): QueryParams => ({
  query:  !cleanQuery ? params.get("q") ?? undefined : "",
});

export const generateContentQueryParams = (params: URLSearchParams): QueryParams  => ({
  query:  params.get("q") ?? "",
  contentFilter: params.get("section") ?? undefined,
});

export const getSearchParams = (qp: QueryParams): string[] => {
  const search: string[] = [];

  // Add filters
  if (qp.query?.length) {
    search.push(`q=${encodeURIComponent(qp.query)}`);
  }
  return search;
};

export const getContentSearchParams = (qp: QueryParams): string[] => {
  const search: string[] = [];

  if (qp.query?.length) {
    // Encode qp.query as url parameter
    search.push(`q=${encodeURIComponent(qp.query)}`);
  }
  return search;
};

export const capitalizeFirstLetter = (string?: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};
