import { FC, ChangeEvent, useState } from "react";

interface CheckboxProps {
  value: string;
  key?: string;
  onChange: (target: string, isChecked: boolean) => void;
  checked?: boolean;
  type?: string;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  additionalClasses?: string;
}

export const checkboxDefaultStyle =
  "inline-block mr-2 w-[18px] h-[18px] accent-CostcoAction-focus bg-gray-100 outline-2 outline-CostcoAction-focus shadow-sm shadow-gray/20";

const Checkbox: FC<CheckboxProps> = ({
  value,
  key,
  onChange,
  checked,
  type,
  readOnly,
  disabled,
  placeholder,
  additionalClasses,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newIsChecked = event.target.checked;
    setIsChecked(newIsChecked);
    onChange(value, newIsChecked);
  };

  const hoverStyle = "hover:border-4";
  return (
    <div className="flex absolute left-2 flex-row w-full" key={key ?? ""}>
      <input
        id="link-checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        type={type || "checkbox"}
        value={value ?? ""}
        readOnly={readOnly}
        disabled={disabled}
        className={`${checkboxDefaultStyle} ${hoverStyle} ${additionalClasses ?? ""}`}
        placeholder={placeholder || ""}
      />
      <label
        htmlFor="link-checkbox"
        className="w-full ms-2 inline-block mt-[-3px] text-gray-900"
      >
        {value}
      </label>
    </div>
  );
};

export default Checkbox;
