import ContentType from "./ContentType";
import ContentStatus from "./ContentStatus";
import ContentSection from "./ContentSection";
/**
 * The PageContent interface defines the structure of configurable data shared on the landing page.
 */
export default interface PageContent {
  type?: ContentType;
  section?: ContentSection;

  title: string;

  subtitle?: string;

  author?: string;

  date?: Date;

  link?: string;

  image?: string;

  status: ContentStatus;

  publishDate?: Date;

  _id?: string;
  modifiedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ApiPageContent {
  type?: ContentType;
  section?: ContentSection;

  title: string;

  subtitle?: string;

  author?: string;

  date?: string;

  link?: string;

  image?: string;

  status: ContentStatus;

  publishDate?: string;

  _id: string;
  modifiedBy: string;
  createdAt?: string;
  updatedAt?: string;
}

export const convertApiPageContent = (apiContentItem: ApiPageContent): PageContent => {
  return {
    ...apiContentItem,
    updatedAt: apiContentItem.updatedAt ? new Date(apiContentItem.updatedAt) : new Date(),
    date: apiContentItem.date ? new Date(apiContentItem.date) : undefined,
    createdAt: apiContentItem.createdAt ? new Date(apiContentItem.createdAt) : new Date(),
    publishDate: apiContentItem.publishDate ? new Date(apiContentItem.publishDate) : undefined,
  }
}
