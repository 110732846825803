import Axios, { AxiosRequestConfig } from "axios";
import ContentMessage from "./interface/ContentMessage";
import ContentMessageStatus from "./interface/ContentMessageStatus";
import PageContent, {
  ApiPageContent,
  convertApiPageContent,
} from "./interface/PageContent";
import QueryParams from "./interface/QueryParams";

export default class ContentApi {
  public static async get(): Promise<PageContent[]> {
    return await ContentApi.getApiContent();
  }

  public static async getTandEDocs(
    queryParams?: QueryParams,
  ): Promise<PageContent[]> {
    return await ContentApi.getApiContent(
      "/training-and-education",
      queryParams,
    );
  }

  public static async getApiContent(
    subpath?: string,
    queryParams?: QueryParams,
  ): Promise<PageContent[]> {
    const requestConfig: AxiosRequestConfig = !queryParams
      ? {}
      : {
          params: { section: queryParams.contentFilter },
        };

    return await Axios.get<ApiPageContent[]>(
      `/content${subpath ?? ""}`,
      requestConfig,
    ).then((response) => {
      return response.data.map(convertApiPageContent);
    });
  }

  public static async loadMetadata(
    link: string,
  ): Promise<PageContent | undefined> {
    try {
      const response = await Axios.post("/content/loadMetadata", { url: link });
      return response.data.content;
    } catch (_exception) {
      return undefined;
    }
  }

  public static async create(content: PageContent): Promise<ContentMessage> {
    return Axios.post("/content", content)
      .then((response) => ({
        content: response.data.items,
        message: "Content created",
        status: ContentMessageStatus.SUCCESS,
      }))
      .catch((exception) => {
        return Promise.reject(new Error(exception));
      });
  }

  public static async update(content: PageContent): Promise<ContentMessage> {
    return Axios.put("/content", content)
      .then((response) => ({
        content: response.data.items,
        message: "Content created",
        status: ContentMessageStatus.SUCCESS,
      }))
      .catch((exception) => {
        return Promise.reject(new Error(exception));
      });
  }

  public static async discard(item: PageContent): Promise<ContentMessage> {
    return await Axios.delete("/content", {
      data: { id: item._id },
    })
      .then((response) => {
        return {
          content: [response.data.item],
          message: "Content discarded",
          status: ContentMessageStatus.SUCCESS,
        };
      })
      .catch((exception) => {
        return Promise.reject(new Error(exception));
      });
  }

  public static async reset() {
    const response = await Axios.get("/content/reset");
    return response.data;
  }
}
